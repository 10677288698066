import { LocationResult, Product } from "@models/models";
import {
  getProductBySlug,
  getProductIdBySlug,
} from "@utils/get-products-helpers";
import { Metadata } from "next";
import { generateProductMetadata } from "@utils/generate-metadata";
import dayjs from "dayjs";

export async function getTransformedProductBySlug(
  slug: string,
): Promise<Product | null> {
  return await getProductBySlug(slug);
}

export async function generateMetadataBySlug(slug: string): Promise<Metadata> {
  const productId = await getProductIdBySlug(slug);
  return productId
    ? await generateProductMetadata(productId)
    : { title: "Not Found" };
}

export function getJsonLd(product: Product) {
  const format = "YYYY-MM-DDTHH:mmZ";

  try {
    if (!product.is_giftcard) {
      const variants = product.activeVariants.length
        ? product.activeVariants
        : product.variants;
      return {
        "@context": "https://schema.org",
        "@type": "Event",
        name: product.title,
        startDate: dayjs(variants[0].startsAtUtc).format(format),
        endDate: dayjs(variants[0].startsAtUtc).add(2, "h").format(format),
        eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
        eventStatus: "https://schema.org/EventScheduled",
        location: {
          "@type": "Place",
          name: product.venueArrangement.venue.name,
          address: {
            "@type": "PostalAddress",
            streetAddress: product.venueArrangement.venue.address,
            addressLocality: product.venueArrangement.venue.city.name,
            postalCode: product.venueArrangement.venue.postalCode,
            addressRegion: product.venueArrangement.venue.province,
            addressCountry: product.venueArrangement.venue.country,
          },
        },
        image: [product.thumbnail],
        description: product.description,
        offers: {
          "@type": "Offer",
          url: "https://www.example.com/event_offer/12345_201803180430",
          price: variants[0].prices[0].amount / 100,
          priceCurrency: variants[0].prices[0].currency_code,
          availability: "https://schema.org/InStock",
        },
        organizer: {
          "@type": "Organization",
          name: product.account.name,
        },
      };
    }
  } catch (e: any) {
    return null;
  }
  return null;
}

export async function initializeLocation() {
  let locationData: LocationResult | null;
  let locationDataString = localStorage.getItem("location");
  if (!locationDataString) {
    locationData = await getIpToLocation();
    if (locationData) {
      localStorage.setItem("location", JSON.stringify(locationData));
    }
    return locationData;
  } else {
    return JSON.parse(locationDataString);
  }
}

async function getIpToLocation(): Promise<LocationResult | null> {
  const result = await fetch("https://pro.ip-api.com/json?key=1F6fHO9M03mcW0O");
  if (result.status === 200) {
    const data = await result.json();
    if (data.status === "success") {
      return data;
    }
  }
  return null;
}
