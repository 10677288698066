"use client";
import { Providers } from "@store/providers";
import "../styles/globals.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { useAppDispatch } from "@store/store";
import React, { useEffect } from "react";
import { usePathname } from "next/navigation";
import { clearMessages } from "@store/messages-slice";
import Script from "next/script";
import Spinner from "@components/spinner";
import { PHProvider } from "./providers";
import { Toaster } from "react-hot-toast";
import { initializeLocation } from "@utils/product-page-utils";
import { fetchCart } from "@store/cart-slice";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <Script id={"google-tag-manager"} strategy={"afterInteractive"}>
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID as string}');
                function gtag(){dataLayer.push(arguments);}
            `}
      </Script>
      <Script id={"facebook-pixel"} strategy={"afterInteractive"}>
        {`
                   !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');   
                `}
      </Script>
      <html lang="en">
        <body>
          <PHProvider>
            <Providers>
              <AppWrapper>
                <Toaster />
                <Spinner />
                {children}
              </AppWrapper>
            </Providers>
          </PHProvider>
        </body>
      </html>
    </>
  );
}

function AppWrapper({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const pathname = usePathname();

  useEffect(() => {
    const load = async () => {
      await initializeLocation();
      dispatch(fetchCart());
    };
    load();
  }, []);

  useEffect(() => {
    dispatch(clearMessages());
  }, [pathname, dispatch]);
  return <>{children}</>;
}
